import React, { useState } from "react";
import "./Home.scss";
import WhoAreWe from "./components/WhoAreWe/WhoAreWe";
import OurServices from "./components/OurServices/OurServices";
import OtherServices from "./components/OtherServices/OtherServices";
import ContactUs from "./components/ContactUs/ContactUs";
import WhyChooseOrrizonte from "./components/WhyChooseOrrizonte/WhyChooseOrrizonte";
import { motion } from 'framer-motion';


const Home = ({setVisible, visible}) => {
    const textVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };
    return (
        <>
            {/* <div className="relative w-full bg-cover bg-center home-container min-h-screen">
                <img src="/assets/images/home/heroImage.svg" alt="Hero-Image" className="w-full h-full object-cover" />
                <div className="absolute top-0 left-0 w-full flex justify-center">
                    <img src="/assets/images/home/orrizonteLogo.svg" alt="Orrizonte" className="mx-auto mt-6" />
                </div> 
                <div className="absolute inset-0 bg-black bg-opacity-0 flex items-center justify-center">
                    <div className="text-center text-white px-4 md:px-8">
                        <h1 className="font-nunito text-4xl md:text-5xl font-bold mb-4"><span className="md:block leading-normal">Empowering Innovation </span>for a Sustainable Future</h1>
                        <p className="text-lg md:text-xl mb-6">
                            <span className="md:block mt-6 leading-relaxed">Join us on a journey towards a brighter, more sustainable future, where</span> innovation and sustainability go hand in hand.
                        </p>
                        <button className="bg-orange-500 text-lg text-white px-8 py-3 rounded-full hover:bg-orange-600 transition duration-300 button-color md:px-12">
                            Get in touch
                        </button>
                    </div>
                </div>
            </div> */}

            <div className="relative bg-cover w-full bg-center min-h-screen flex items-center justify-center text-center">
                <div className="fixed-width-container">
                    <img src="/assets/images/home/heroImage.svg" alt="Hero-Image" className="fixed-dimensions" />
                </div>
                <div className="absolute top-0 left-0 w-full flex flex-col justify-center z-10">
                    <img src="/assets/images/home/orrizonteLogo.svg" alt="Orrizonte" className="mx-auto mt-6" />
                </div>

                <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center z-10 p-4 text-center">
                    <motion.h1 className="text-4xl md:text-5xl font-bold text-white" initial="hidden"
                    animate="visible"
                    variants={textVariants}
                    transition={{ duration: 1 }}><span className="md:block leading-normal">Empowering Innovation </span>for a Sustainable Future</motion.h1>
                    <motion.p className="text-lg md:text-xl text-white mb-6" initial="hidden"
                    animate="visible"
                    variants={textVariants}
                    transition={{ duration: 1, delay: 0.2 }}><span className="md:block mt-6 leading-relaxed">Join us on a journey towards a brighter, more sustainable future, where</span> innovation and sustainability go hand in hand.</motion.p>
                    <button type="button" onClick={() => setVisible(!visible)}  className="bg-orange-500 text-lg md:text-xl text-white px-8 py-3 rounded-full hover:bg-orange-600 transition duration-300 button-color md:px-12">
                    Get in touch
                    </button>
                </div>
            </div>
            <WhoAreWe />
            <OurServices />
            <OtherServices />
            <WhyChooseOrrizonte />
            <ContactUs setVisible={setVisible} visible={visible} />
        </>
    )
}

export default Home;